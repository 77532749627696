@import '../../../WithStyle/general';

.nav-links {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.nav-links li {
  margin-bottom: 1rem; 
  width: 95%;
}
.nav-links a {
  font-size: 1.8rem;
  border: 1px solid transparent;
  border-radius: 5px 20px 5px 20px;
  color: colorie(basefontcolor);
  text-decoration: none;
  padding: 0.3rem;
  margin-right: 0.5rem;
  overflow: hidden;
  background-clip: padding-box;
  transition: background-color 350ms linear;
  font-variant: small-caps;
}
.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background-color:#16181a;
  border-color: #292929;
  color: colorie(basefontcolor);
}

@media (min-width: 768px) {
    .nav-links {
      flex-direction: row;
      margin: 0px;
      align-items: center;
    }
    .nav-links a {
      font-size: 1.3rem;
    }
    .nav-links li {
      margin-bottom: 0rem; 
    }
}
