@import '../../../WithStyle/general';

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 1vmin);
  color: colorie(basefontcolor);
  -webkit-box-shadow: 0 7px 11px rgb(8, 8, 8, 0.7);
  -moz-box-shadow: 0 7px 11px rgb(8, 8, 8, 0.7);
  box-shadow: 0 7px 11px rgb(8, 8, 8, 0.7);
  overflow: hidden;
  box-sizing: border-box;
  background: radial-gradient(circle, rgba(22,32,38,1) 0%, rgba(44,76,100,0.9713818632921919) 85%);
  z-index: zindex(header);
  border-radius: 0% 0% 5% 5%;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}