@import "./WithStyle/general";

body {
  font-family: 'Mukta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:colorie(basecolor);
  color: colorie(basefontcolor);
}

main {
  margin-top: 3.9rem;
  position: relative;
  
}

.drawer-slide-trans-enter {
  transform: translateX(-100%);
}

.drawer-slide-trans-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 500ms;
}

.drawer-slide-trans-exit {
  transform: translateX(0%);
  opacity: 1;
}

.drawer-slide-trans-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 400ms;
}

.superCenter {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

