@import '../../../WithStyle/general';

.side-drawer{
    position: fixed;
    top: 6px;
    left: 5px;
    bottom: 7px;
    z-index: zindex(sidedrawer);
    width: 45vw;
    background: linear-gradient(310deg, rgb(0, 5, 12), 40%, colorie(buttoncolor));
    border-radius: 4px 10px 10px 4px;
    -webkit-box-shadow: 8px 8px 9px -3px rgba(0, 0, 0, 0.8);
    box-shadow: 8px 8px 9px -3px rgba(0, 0, 0, 0.8);
    border: 1px solid #49565a;
    padding-left: 10px;;
}