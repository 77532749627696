@import '../../../WithStyle/general';

.main-navig-logo {
  height: 2.4rem;
  pointer-events: none;
  vertical-align: baseline;
}
/*@media (prefers-reduced-motion: no-preference) {
  .main-navig-logo {
    animation: header-logo-spin infinite 6s linear;
  }
}
@keyframes header-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
.main-navig__menu-button {
  height: 2.4rem;
  background: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 2px;
  justify-content: space-around;
  margin-top: 6px;
  margin-left: 6px;
  margin-right: 15px;
  border-radius: 4px;
  box-shadow: 4px 4px 5px #111c23,
    -4px -4px 5px #233845;
}
.main-navig__menu-button span {
  display: block;
  background: brown;
  height: 3px;
  width: 1.9rem;
}

.main-navig__title {
  box-sizing: border-box;
  margin-top: 0px;
  display: flex;
  align-items: center;
  font-family: 'Sansita Swashed', cursive;
  padding: 0.2rem;;
 }
 .main-navig__title span{
  display: inline-block;
 }
.main-navig__title-content {
  margin: 0px;
  font-size: 2.5rem;
  }
.main-navig__title-content a {
  text-decoration: none;
  color: colorie(contraste);
}
.main-navig__bonjour {
  font-size: 1.1rem;
  margin-left: 30px;
}
.main-navig__header-nav {
  display: none;
}
.main-navig__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navig__menu-button {
    display: none;
  }
  .main-navig__header-nav {
    display: block;
  }
}
